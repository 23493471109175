import HomePage from "@/components/HomePage";
import { withPages } from "HoCs/withPages";
import Head from "next/head";
import { home } from "../SEO";

const Home = ({ data }) => {
  return (
    <div>
      <Head>
        <meta name="robots" content="index" />
        <meta name="AdsBot-Google" content="noindex" />
        <script type="text/javascript" src="/static/crisp.js?v=5"></script>
        {process.env.mode == "PRODUCTION" ? (
          <script type="text/javascript" src="/static/hotjar.js"></script>
        ) : null}
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css"
        ></link>
        {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
        {/* <script async src="https://www.googletagmanager.com/gtag/js?id=UA-228381407-1"></script> */}
        {/* <script type="text/javascript" src="/static/gga.js"></script> */}
      </Head>
      <HomePage data={data} />
    </div>
  );
};

export default withPages(home, ["home", "review"], "Home Page")(Home);
