export const evenOdd = (a) => a % 2 === 0;

export const renderBackground = (components) => {
  return components.map((item, index) => (
    <div
      className={evenOdd(index) ? `background-dark` : `background-light`}
      style={
        evenOdd(index)
          ? { backgroundColor: "#F7F8F9", padding: "0 0" }
          : { backgroundColor: "#F7F8F9", padding: "0 0" }
      }
      key={index}
    >
      {item}
    </div>
  ));
};
