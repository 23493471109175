import React from "react";
import Image from "next/future/image";

const ReasonToUse = () => {
  return (
    <div className="container">
      <div className="row blockify-padding">
        <div className="col-lg-6 col-md-6 col-sm-12 blockify-center">
          <img
            src="images/homepage/newImage/image1.png"
            alt="Your Image"
            className="img-fluid"
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 my-auto">
          <h1 className="blockify-title">
            The importance of{" "}
            <span className="blockify-secondary-text">Right Block</span>
          </h1>
          <p className="blockify-content">
            Dealing with fake international orders every day, facing an{" "}
            <b>increase in chargebacks</b>, and battling <b>content theft</b> or{" "}
            <b>spying</b> all drain resources. Additionally, owning{" "}
            <b>multiple stores and a complex product range</b> that needs to
            reach the right buyers can be daunting. Born with the mission of
            keeping your store safe only with real buyers, we designed{" "}
            <b className="blockify-secondary-text">Blockify</b> with smart and
            simple rules to protect your revenue, enhance your customer shopping
            experience and fortify your competitive advantages
          </p>
        </div>
      </div>
    </div>
  );
};

export default ReasonToUse;
