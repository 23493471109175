import React from "react";
import Image from "next/future/image";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";

const CustomerReview = () => {
  const config = [
    {
      name: "LC",
      position: "The United Arab Emirates",
      description: [
        "Best blocker app in Shopify app store. Helped us a lot with DDOS and phishing attacks during the sale period. They have an auto VPN tracker that blocks the page if a VPN is used to place an order so no more flags from the payment gateway and customer service is always great.",
      ],
      avatar: "/images/homepage/newImage/review1.png",
    },
    {
      name: "The Hippie Collection",
      position: "Philippines",
      description: [
        "The app is great, and the customer support is responsive. It helped us minimize the fraud orders, it's not removed. But they have a new updated feature to solve this issue and hope it will work better! I appreciate the guy’s responsiveness and patience to solve my problems.",
      ],
      avatar: "/images/homepage/newImage/review2.png",
    },
    {
      name: "Crohm",
      position: "Netherlands",
      description: [
        "Totally recommend it. I encountered two problems. One was totally on my side (the IP address that I found on my PC turned out not to be my actual IP address). The other one had to do with the integration into the theme of my website, which was quickly fixed. Customer service was a warm and rewarding experience: very swift response time, patient, friendly, and capable. The app does now exactly what I need it to do.",
      ],
      avatar: "/images/homepage/newImage/review3.png",
    },
    {
      name: "Ashay By The Bay",
      position: "United States",
      description: [
        "I have a Shopify book store that has 1000+ products and I was greatly concerned that a cyber group had copied content and was stealing my traffic. But I discovered the Blockify App and enable me to block IP\"s, Regions, Bots and VPN's as needed to help me further protect my content. I can turn it off and on and it is easily integrated into my 2.0 theme. This is one of the best Apps for more intensified security for your store.",
      ],
      avatar: "/images/homepage/newImage/review4.png",
    },
  ];
  const firstMarkup = (item) => (
    <div className="st-partner-slides-inner pb-5">
      <div className="d-flex text-center">
        {item.description.map((des, index) => (
          <span className="blockify-review-content" key={index}>
            {des}
          </span>
        ))}
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <div style={{ width: "10%", textAlign: "end" }}>
          <Image
            src={item.avatar}
            width={20}
            height={20}
            alt="avatar"
            layout="responsive"
            objectFit="contain"
            sizes="(max-width: 768px) 100vw, (max-width: 1024px) 50vw, 20px"
          />
        </div>
        <div className="blockify-review-name">
          {item.name} /{" "}
          <span className="blockify-position">{item.position}</span>
        </div>
      </div>
      <div className="testing"></div>
    </div>
  );
  return (
    <div className="omg-happy-customer-wrapper ptb-3rem">
      <div className="container">
        <div className="auto-rem">
          <h1 className="blockify-title text-center">
            Listen to Our <span className="blockify-secondary-text">Real</span>{" "}
            Store Owners
          </h1>
          <div className="text-center">
            <img src="/images/homepage/newImage/comma.png" alt="comma" />
          </div>
        </div>
        <div className="row">
          <Swiper
            modules={[Pagination, Autoplay]}
            spaceBetween={50}
            slidesPerView={1}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            pagination={{ clickable: true }}
          >
            {config.map((item, index) => (
              <SwiperSlide key={index}>{firstMarkup(item)}</SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default CustomerReview;
