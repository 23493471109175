import React from "react";
import Falcon from "../SVGS/icons/Falcon";
const Button = () => {
  return (
    <button
      className="btn-main"
      onClick={() => window.open("https://apps.shopify.com/blockify", "_blank")}
    >
      <Falcon />
      Get started
    </button>
  );
};

export default Button;
