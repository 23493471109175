import React, { useRef } from "react";
import HeroBanner from "./common/HeroBanner";
import HowItWork from "./common/HowItWork";
import PricingTable from "./common/PricingTable";
import { renderBackground } from "utils";
import CustomerReview from "./common/CustomerReview";
import FAQ from "./common/FAQ";
import GetReady from "./common/GetReady";
import ReasonToUse from "./common/ReasonToUse";
import BenefitPoint from "./common/BenefitPoint";
import GetInformation from "./common/GetInformation";
import Features from "./common/Features";
import Navbar from "./Navbar";

const HomePage = ({ data }) => {
  const { home } = data;
  const { heroBanner, benefit, features, pricing, ecommerces, setUp } = home;
  const heroBannerRef = useRef(null);
  const pricingTableRef = useRef(null);
  const aboutUsRef = useRef(null);
  const faqRef = useRef(null);

  const scrollToHeroBanner = () => {
    if (heroBannerRef.current) {
      heroBannerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  const scrollToPricingTable = () => {
    if (pricingTableRef.current) {
      pricingTableRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  const scrollToAboutUs = () => {
    if (aboutUsRef.current) {
      aboutUsRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  const scrollToFAQ = () => {
    if (faqRef.current) {
      faqRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  // const openCrisp = () => {
  //   try {
  //     $crisp.push(["do", "chat:open"]);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const openEmailClient = () => {
    const emailAddress = "support@omegatheme.com";
    const subject = "";
    const body = "";

    const mailtoUrl = `mailto:${emailAddress}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoUrl;
  };
  const components = [
    <div ref={heroBannerRef} style={{ paddingBottom: "3rem" }}>
      <HeroBanner data={[heroBanner, ecommerces, benefit]} />
    </div>,
    <BenefitPoint data={[benefit]} />,
    <ReasonToUse />,
    <div ref={aboutUsRef}>
      <HowItWork data={setUp} />
    </div>,
    <Features data={features} />,
    <div ref={pricingTableRef}>
      <PricingTable data={pricing} />
    </div>,
    <CustomerReview />,
    <div ref={faqRef}>
      <FAQ />
    </div>,
    <GetReady />,
    <GetInformation />,
  ];

  return (
    <article>
      <Navbar
        scrollToHeroBanner={scrollToHeroBanner}
        scrollToAboutUs={scrollToAboutUs}
        scrollToPricingTable={scrollToPricingTable}
        scrollToFAQ={scrollToFAQ}
        // openCrisp={openCrisp}
        openEmailClient={openEmailClient}
      />
      {renderBackground(components)}
    </article>
  );
};

export default HomePage;
