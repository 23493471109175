import React from "react";
import Image from "next/future/image";

const PricingTable = ({ data }) => {
  const pricing = data;
  return (
    <div className="omg-how-it-works">
      <div className="omg-how-it-works-content">
        <div className="container">
          <div className="row omg-how-it-works-content-title">
            <h2 className="display-2 text-center omg-how-it-works-content-special">
              {pricing.title}
            </h2>
            <p className="text-center body-lg omg-how-it-works-content-special">
              {pricing.description}
            </p>
          </div>
          <div className="row">
            {pricing.content.map((item, index) => (
              <div key={index} className="col-lg-3">
                <div
                  className={`card text-center ${
                    item.plan === "Premium" ? "premium-plan" : ""
                  }`}
                >
                  <div className="card-body">
                    {item.plan === "Premium" && (
                      <span className="best-seller">
                        <img
                          src="/images/homepage/newImage/icon_star.png"
                          alt="star"
                          style={{ margin: "0px 4px 4px 0px" }}
                        ></img>
                        {item.badge}
                      </span>
                    )}
                    <h5 className="card-title">{item.plan}</h5>
                    <h6 className="card-price">
                      {item.price}
                      <span className="card-unit">{item.unit}</span>
                    </h6>
                    <p className="card-unit">{item.yearlyPrice}</p>
                    <div className="card-benefits">
                      {item.benefits.map((benefit, index) => (
                        <div key={index} className="card-items">
                          <Image
                            src="/images/homepage/newImage/icon_checked.png"
                            alt="icon_check"
                            width={12}
                            height={12}
                            className="card-margin"
                          />
                          {benefit}
                        </div>
                      ))}
                    </div>
                  </div>
                  <button
                    className={`btn-pricing ${
                      item.plan === "Premium" ? "btn-active" : ""
                    }`}
                    onClick={() =>
                      window.open("https://apps.shopify.com/blockify", "_blank")
                    }
                  >
                    Get started
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingTable;
