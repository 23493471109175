const BenefitPoint = ({ data }) => {
  const [benefit] = data;

  return (
    <div className="container">
      <div className="blockify-wrapper">
        {benefit.map((item, index) => (
          <div key={index}>
            <div className="blockify-icon">
              <img src={item.icon} alt="benefit_img" />
            </div>
            <div className="blockify-title">{item.title}</div>
            <div className="blockify-content-padding">{item.subtitle}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BenefitPoint;
