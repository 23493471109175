import Link from "next/link";
import NavIcon from "../SVGS/icons/NavIcon";
import Image from "next/future/image";
import logo from "../../public/images/homepage/newImage/Blockify_logo.png";

const Logo = () => {
  return (
    <>
      <Link href="/">
        <a className="navbar-brand">
          <Image alt="..." src={logo} height={40} />
        </a>
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon">
          <NavIcon />
        </span>
      </button>
    </>
  );
};

export default Logo;
