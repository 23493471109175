import React from "react";
import Falcon from "../SVGS/icons/Falcon";

const GetReady = () => {
  return (
    <div className="omg-get-ready-wrapper ptb-3rem">
      <div className="container">
        <div className="d-flex blockify-ready-wrapper">
          <div className="d-flex align-items-center blockify-ready-text">
            <div>
              <h1 className="blockify-title">
                Start your{" "}
                <span className="blockify-secondary-text">free trial</span>{" "}
                today
              </h1>
              <p className="blockify-content">
                Advertise and sell to real buyers, tailor your product offerings
                by location, and eliminate invalid traffic with Blockify.
              </p>
              <button
                className="btn-main mt-3"
                onClick={() =>
                  window.open("https://apps.shopify.com/blockify", "_blank")
                }
              >
                <Falcon />
                Try free now
              </button>
            </div>
          </div>
          <div className="d-flex align-items-center blockify-get-ready-img">
            <img
              src="/images/homepage/newImage/image11.png"
              style={{ width: "80%" }}
              alt="get ready to started blockify"
              className="optimize-img"
            />
          </div>
        </div>
      </div>
      <div className="omg-get-ready-decor"></div>
    </div>
  );
};

export default GetReady;
