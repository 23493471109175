import { useState } from "react";
const NavLink = ({
  scrollToHeroBanner,
  scrollToPricingTable,
  scrollToAboutUs,
  scrollToFAQ,
  // openCrisp,
  openEmailClient,
}) => {
  const dataMapNavbar = [
    {
      title: "Product",
    },
    {
      title: "About us",
    },
    {
      title: "Pricing",
    },
    {
      title: "Resources",
    },
    {
      title: "Contact",
    },
  ];
  const [highlightedTitle, setHighlightedTitle] = useState("");
  const handleClick = (title) => {
    setHighlightedTitle(title);

    if (title === "Product") {
      scrollToHeroBanner();
    } else if (title === "Pricing") {
      scrollToPricingTable();
    } else if (title === "About us") {
      scrollToAboutUs();
    } else if (title === "Resources") {
      scrollToFAQ();
    } else {
      // openCrisp();
      openEmailClient();
    }
  };
  return (
    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
      {dataMapNavbar.map((item, index) => {
        return (
          item.subTitle === undefined && (
            <li className="nav-item" key={index}>
              <div
                className={`nav-link active body-sm ${
                  highlightedTitle === item.title ? " highlighted" : ""
                }`}
                onClick={() => handleClick(item.title)}
              >
                {item.title}
              </div>
            </li>
          )
        );
      })}
    </ul>
  );
};

export default NavLink;
