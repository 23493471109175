import { Autoplay, Navigation, Pagination } from "swiper";
import Falcon from "../SVGS/icons/Falcon";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/react";
import Image from "next/future/image";
import banner from "../../public/images/homepage/newImage/banner.png";

const HeroBanner = ({ data }) => {
  const [heroBanner, ecommerces] = data;
  const mainMarkup = (
    <div className="omg-hero-banner-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 banner-content">
            <div className="row omg-hero-banner">
              <div className="omg-hero-banner-content">
                <div className="col">
                  <h1 className="hero-title">
                    <span className="highlight-title">Block</span> unwanted
                    access and <span className="highlight-title">protect</span>{" "}
                    your store
                  </h1>
                  <p className="body-lg">{heroBanner.content}</p>
                  <p className="pink-bg">
                    <img
                      src="/images/homepage/newImage/diamon.png"
                      style={{ width: "16px", marginRight: "4px" }}
                    />
                    Built for Shopify
                  </p>
                  <div style={{ display: "flex" }}>
                    <button
                      className="btn-main"
                      onClick={() =>
                        window.open(
                          "https://apps.shopify.com/blockify",
                          "_blank"
                        )
                      }
                    >
                      <Falcon />
                      {heroBanner.button}
                    </button>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "3rem 0",
                }}
              >
                {ecommerces.content.map((item, index) => (
                  <div
                    key={index}
                    className="col-md-2"
                    style={{ marginRight: "6rem" }}
                  >
                    <a href={item.href} target="_blank" rel="noopener">
                      <Image
                        src={`/images/${item.image}`}
                        alt={item.alt}
                        height={30}
                        width={100}
                      />
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <Image
              src={banner}
              sizes="(max-width: 768px) 100vw, 800px"
              width={800}
              height={560}
              alt="hero banner blockify app"
              className="optimize-img"
            />
          </div>
        </div>
      </div>
    </div>
  );

  const carouselConfig = [mainMarkup];
  return (
    <div>
      <Swiper
        // install Swiper modules
        modules={[Pagination, Autoplay, Navigation]}
        spaceBetween={50}
        slidesPerView={1}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        navigation
        pagination={{ clickable: true }}
        // scrollbar={{ draggable: true }}
        // onSwiper={(swiper) => console.log(swiper)}
        // onSlideChange={() => console.log("slide change")}
      >
        {carouselConfig.map((item, index) => (
          <SwiperSlide key={index}>{item}</SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default HeroBanner;
