import React, { useState } from "react";
import Swal from "sweetalert2";

function GetInformation() {
  const [email, setEmail] = useState("");
  const validateEmail = (email) => {
    const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return pattern.test(email);
  };
  const handleEmail = () => {
    if (!validateEmail(email)) {
      Swal.fire({
        icon: "error",
        confirmButtonColor: "#FE5722",
        text: "Please enter a valid email address!",
      });
      return;
    }

    const apiUrl =
      "https://script.google.com/macros/s/AKfycbxCnKtUe0Y8yvOZPVgjvbDZeALBdAi2hW_4BJq9Sp5WWp4R6Y24Onr1HdGHaid4LI-1ng/exec";
    const formData = new FormData();
    formData.append("Email", email);
    fetch(apiUrl, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setEmail("");
        Swal.fire({
          icon: "success",
          confirmButtonColor: "#FE5722",
          text: "Your email has been sent successfully!",
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <div className="linear-background">
      <div className="container">
        <div className="blockify-get-info">
          <div>
            <h1 className="blockify-title">
              Never miss the latest{" "}
              <span className="blockify-secondary-text">Insight!</span>
            </h1>
            <p className="blockify-content">
              No spam. No data breach. You may opt out at any time.
            </p>
          </div>
          <div className="d-flex blockify-get-mail">
            <input
              placeholder="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button onClick={handleEmail}>SUBSCRIBE</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetInformation;
