import React from "react";

const Features = ({ data }) => {
  const features = data;
  return (
    <div className="container">
      <div className="blockify-row blockify-padding">
        <div className="col-sm-12 col-md-12 col-lg-6 blockify-feature-img">
          <div>
            <img
              src="images/homepage/newImage/image5.png"
              alt="Your Image"
              className="img-fluid"
            />
          </div>
          <div className="blockify-flex-end">
            <img
              src="images/homepage/newImage/image10.png"
              alt="Your Image"
              className="img-fluid"
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-6 blockify-padding-left">
          <h1 className="blockify-title blockify-res-title">
            Block & Redirect with{" "}
            <span className="blockify-secondary-text">Ease of mind</span>
          </h1>
          <p className="blockify-content">
            Gain unmatched control and customization with Blockify's Block
            feature, offering a wide range of rules for comprehensive security
            management.
          </p>
          <div className="divide"></div>
          {features.easeOfMind.map((item, index) => (
            <div key={index} className="blockify-container-content">
              <img
                src="/images/homepage/newImage/icon_check.png"
                alt="check"
                style={{ marginTop: "16px" }}
              />
              <div className="blockify-container-detail">
                {item.title}{" "}
                <span className="blockify-subtext">{item.text}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="blockify-row-res-reverse blockify-padding">
        <div className="col-sm-12 col-md-12 col-lg-6">
          <h1 className="blockify-title blockify-res-title">
            Gain <span className="blockify-secondary-text">deep</span> insights
          </h1>
          <p className="blockify-content">
            Our Visitor Analytics provides information about website visitors,
            including their IP address, geolocation country, and browser
            details.
          </p>
          <div className="divide"></div>
          {features.gainDeepInsight.map((item, index) => (
            <div key={index} className="blockify-container-content">
              <img src="/images/homepage/newImage/icon_check.png" alt="check" />
              <div className="blockify-container-detail">
                {item.title}{" "}
                <span className="blockify-subtext">{item.text}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="col-sm-12 col-md-12 col-lg-6 blockify-padding-left blockify-feature-img">
          <div>
            <img
              src="images/homepage/newImage/image8.png"
              alt="Your Image"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
      <div className="blockify-row blockify-padding">
        <div className="col-sm-12 col-md-12 col-lg-6 blockify-feature-img">
          <div>
            <img
              src="images/homepage/newImage/image9.png"
              alt="Your Image"
              className="img-fluid"
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-6 blockify-padding-left">
          <h1 className="blockify-title blockify-res-title">
            Enhance <span className="blockify-secondary-text">Competitive</span>{" "}
            Advantage
          </h1>
          <p className="blockify-content">
            Enhance your content security with Blockify's anti-copy feature,
            designed to protect your intellectual property.
          </p>
          <div className="divide"></div>
          {features.competitive.map((item, index) => (
            <div key={index} className="blockify-container-content">
              <img src="/images/homepage/newImage/icon_check.png" alt="check" />
              <div className="blockify-container-detail">
                {item.title}{" "}
                <span className="blockify-subtext">{item.text}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features;
