import React, { useState } from "react";
import Image from "next/future/image";

const FAQ = () => {
  const [selectedQuestions, setSelectedQuestions] = useState([]);

  const faqData = [
    {
      question: "What is ISP?",
      answer1:
        'Internet service provider (ISP) is an organization that provides services for accessing, using, managing, or participating in the <a href="https://en.wikipedia.org/wiki/Internet" target="_blank">Internet</a>. Learn more about <a href="https://en.wikipedia.org/wiki/Internet_service_provider" target="_blank">ISP</a>.',
    },
    {
      question:
        "Why do the data on Google Analytics or other analytics app still show visitors from a blocked country?",
      answer1:
        "Due to Google Analytics (and other analytics apps) visits logging mechanism, all visiting attempts will still be recorded in the analytic report.",
      answer2:
        "Rest assured that those logs are just visiting attempts that got blocked immediately by Blockify.",
    },
    {
      question: "How can I access my store if I have blocked my own country?",
      answer1:
        "We have an admin access URL for your store in the Setting tab. Access via that URL will not be blocked under any circumstances. Please keep the link disclosed.",
    },
    {
      question: "How to allow only a country to access my site?",
      answer1:
        'Step 1: Go to the "Blocker & Redirector" tab, select "Block" and click "Block country". In the pop-up, select "Add all countries" to block access from every country and click "Add".',
      answer2:
        'Step 2: Select "Whitelist" and click "Whitelist country". In the pop-up, select a country or every country that you want to allow access from and click "Add".',
    },
  ];

  const toggleQuestion = (index) => {
    if (selectedQuestions.includes(index)) {
      setSelectedQuestions(
        selectedQuestions.filter((selected) => selected !== index)
      );
    } else {
      setSelectedQuestions([...selectedQuestions, index]);
    }
  };

  return (
    <div className="omg-happy-customer-wrapper ptb-3rem">
      <div className="container">
        <div className="auto-rem">
          <h1 className="blockify-title text-center">
            Frequently asked questions
          </h1>
        </div>
        <div className="row">
          <div className="faq-container">
            {faqData.map((faq, index) => (
              <div key={index} className="faq-item">
                <div
                  className="faq-question"
                  onClick={() => toggleQuestion(index)}
                >
                  {faq.question}
                  {selectedQuestions.includes(index) ? (
                    <span>
                      <Image
                        src="/images/homepage/newImage/chevron-up.png"
                        alt="up"
                        width={16}
                        height={16}
                      />
                    </span>
                  ) : (
                    <span>
                      <Image
                        src="/images/homepage/newImage/chevron-down.png"
                        alt="down"
                        width={16}
                        height={16}
                      />
                    </span>
                  )}
                </div>
                {selectedQuestions.includes(index) && (
                  <div>
                    <hr />
                    <div
                      className="faq-answer"
                      dangerouslySetInnerHTML={{ __html: faq.answer1 }}
                    ></div>
                    <div className="faq-answer">{faq.answer2}</div>
                  </div>
                )}
                {index !== faqData.length - 1 && <hr />}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
