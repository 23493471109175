import ScrollAnimation from "react-animate-on-scroll";
const HowItWork = ({ data }) => {
  const { content } = data;
  return (
    <div className="omg-how-it-works">
      <div className="omg-how-it-works-content">
        <div className="container">
          <div className="row omg-how-it-works-content-title">
            <h2 className="display-2 text-center">
              What Makes{" "}
              <span className="omg-how-it-works-content-special">Blockify</span>{" "}
              Different?
            </h2>
            <p className="text-center body-lg">
              Blockify gives you powerful control over your site
            </p>
          </div>
          <div className="row">
            {content.map((card, index) => (
              <div className="col-md-4" key={index}>
                <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                  <div className="omg-card text-center" key={index}>
                    <div style={{ marginBottom: "1rem", height: "170px" }}>
                      <img
                        className="blockify-card-img"
                        src={card.img}
                        alt="step"
                      />
                    </div>
                    <div>
                      <h3 className="st-h5">{card.title}</h3>
                      <p className="body-lg">{card.content}</p>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWork;
